import { KeyValuePair, ResolvableTo } from "tailwindcss/types/config";

export const lineHeight: ResolvableTo<KeyValuePair> = {
  100: "0.875rem",
  200: "1rem",
  300: "1.25rem",
  400: "1.375rem",
  500: "1.75rem",
  600: "2rem",
  700: "2.25rem",
  800: "2.5rem",
  900: "3.25rem",
  1000: "5.75rem"
};
