import { KeyValuePair, ResolvableTo } from "tailwindcss/types/config";

export const boxShadow: ResolvableTo<KeyValuePair<string, string>> = {
  // use blur of 10px with all shadows
  "subtle-sm":
    "0px 1px 0px 0px rgba(0, 0, 0, 0.03), 0px 2px 2px 0px rgba(0, 0, 0, 0.03), 0px 5px 5px 0px rgba(0, 0, 0, 0.05)",
  "blur-sm":
    "0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0px 10px 10px 0px rgba(0, 0, 0, 0.10)",
  "blur-md":
    "0px 2px 4px 0px rgba(0, 0, 0, 0.10), 0px 5px 10px 0px rgba(0, 0, 0, 0.10), 0px 15px 30px 0px rgba(0, 0, 0, 0.15)",
  "blur-lg":
    "0px 3px 6px 0px rgba(0, 0, 0, 0.10), 0px 10px 20px 0px rgba(0, 0, 0, 0.10), 0px 15px 30px 0px rgba(0, 0, 0, 0.15)",
  "blur-xl":
    "0px 5px 10px 0px rgba(0, 0, 0, 0.10), 0px 15px 30px 0px rgba(0, 0, 0, 0.10), 0px 20px 40px 0px rgba(0, 0, 0, 0.15)",
  "blur-subtle-sm":
    "0px 1px 0px 0px rgba(0, 0, 0, 0.03), 0px 2px 2px 0px rgba(0, 0, 0, 0.03), 0px 5px 5px 0px rgba(0, 0, 0, 0.05)",
  "blur-subtle-md":
    "0px 1px 0px 0px rgba(0, 0, 0, 0.03), 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0px 8px 8px 0px rgba(0, 0, 0, 0.05)",
  "blur-subtle-lg":
    "0px 1px 0px 0px rgba(0, 0, 0, 0.03), 0px 6px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 10px 0px rgba(0, 0, 0, 0.05)",
  "blur-subtle-xl":
    "0px 1px 0px 0px rgba(0, 0, 0, 0.03), 0px 8px 8px 0px rgba(0, 0, 0, 0.05), 0px 12px 12px 0px rgba(0, 0, 0, 0.06)",
  "blur-strong-sm":
    "0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.10), 0px 10px 10px 0px rgba(0, 0, 0, 0.15)",
  "blur-strong-md":
    "0px 2px 4px 0px rgba(0, 0, 0, 0.10), 0px 5px 10px 0px rgba(0, 0, 0, 0.10), 0px 10px 20px 0px rgba(0, 0, 0, 0.20)",
  "blur-strong-lg":
    "0px 3px 6px 0px rgba(0, 0, 0, 0.10), 0px 10px 20px 0px rgba(0, 0, 0, 0.15), 0px 15px 30px 0px rgba(0, 0, 0, 0.20)",
  "blur-strong-xl":
    "0px 5px 10px 0px rgba(0, 0, 0, 0.05), 0px 15px 30px 0px rgba(0, 0, 0, 0.15), 0px 20px 40px 0px rgba(0, 0, 0, 0.25)",
  // "inner-shadow-blur-sm":
  //   "0px 20px 50px 0px rgba(255, 255, 255, 0.15) inset, 0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0px 10px 10px 0px rgba(0, 0, 0, 0.10)",
  // "inner-shadow-blur-md":
  //   "0px 20px 50px 0px rgba(255, 255, 255, 0.15) inset, 0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0px 10px 10px 0px rgba(0, 0, 0, 0.10)",
  // "inner-shadow-blur-lg":
  //   "0px 20px 50px 0px rgba(255, 255, 255, 0.15) inset, 0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0px 10px 10px 0px rgba(0, 0, 0, 0.10)",
  // "inner-shadow-blur-xl":
  //   "0px 20px 50px 0px rgba(255, 255, 255, 0.15) inset, 0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0px 10px 10px 0px rgba(0, 0, 0, 0.10)",
  "blur-10": "",
  "blur-20": "",
  "blur-30": "",
  "blur-40": ""
};
