import { KeyValuePair, ResolvableTo } from "tailwindcss/types/config";

export const borderRadius: ResolvableTo<KeyValuePair> = {
  none: "0rem",
  4: "0.25rem",
  6: "0.375rem",
  7: "0.438rem",
  8: "0.5rem",
  xs: "0.5rem",
  small: "0.75rem",
  medium: "1rem",
  large: "1.25rem",
  xl: "1.5rem",
  "2xl": "1.75rem",
  "3xl": "2rem",
  circular: "6.188rem",
  sm: "0.75rem"
};
