import { RecursiveKeyValuePair, ResolvableTo } from "tailwindcss/types/config";

export const colors: ResolvableTo<RecursiveKeyValuePair> = {
  bordergradient: "var(--container-border)",
  border: "var(--container-border)",
  input: "hsl(var(--input))",
  ring: "hsl(var(--ring))",
  background: "hsl(var(--background))",
  foreground: "hsl(var(--foreground))",
  primary: {
    DEFAULT: "hsl(var(--primary))",
    foreground: "hsl(var(--primary-foreground))"
  },
  secondary: {
    DEFAULT: "hsl(var(--secondary))",
    foreground: "hsl(var(--secondary-foreground))"
  },
  destructive: {
    DEFAULT: "hsl(var(--destructive))",
    foreground: "hsl(var(--destructive-foreground))"
  },
  muted: {
    DEFAULT: "hsl(var(--muted))",
    foreground: "hsl(var(--muted-foreground))"
  },
  popover: {
    DEFAULT: "hsl(var(--popover))",
    foreground: "hsl(var(--popover-foreground))"
  },
  card: {
    DEFAULT: "hsl(var(--card))",
    foreground: "hsl(var(--card-foreground))"
  },
  "bg-container": {
    primary: {
      DEFAULT: "var(--container-background-primary)",
      foreground: "var(--container-background-primary)"
    },
    secondary: {
      DEFAULT: "var(--container-background-secondary)",
      foreground: "var(--container-background-secondary)"
    },
    tertiary: {
      DEFAULT: "var(--container-foreground-tertiary)"
    }
  },
  "status-generic-beige-foreground-2": {
    rest: "var(--status-generic-beige-foreground-2-rest)"
  },
  "status-generic-peach-foreground-2": {
    rest: "var(--status-generic-peach-foreground-2-rest)"
  },
  "gradient-secondary": {
    DEFAULT: "var(--gradient-secondary)"
  },
  "status-Generic-Beige-Background": {
    2: "var(--status-Generic-Beige-Background-2)",
    rest: "var(--status-Generic-Beige-Background-2-rest)"
  },
  "status-Generic-Beige-Stroke-Active": {
    Rest: "var(--status-Generic-Beige-stroke-Active-rest)"
  },
  "status-generic-cornflower-background-2": {
    rest: "var(--status-generic-cornflower-background-2-rest)"
  },
  "status-generic-navy-stroke-active": {
    rest: "var(--status-generic-navy-stroke-active-rest)"
  },
  color: {
    primary: {
      DEFAULT: "var(--color-primary)",
      foreground: "var(--color-primary)"
    },
    contrast: {
      DEFAULT: "var(--color-primary-contrast)"
    },
    secondary: {
      DEFAULT: "var(--color-secondary)",
      foreground: "var(--color-secondary)"
    },
    tertiary: {
      DEFAULT: "var(--color-tertiary)",
      foreground: "var(--color-tertiary)"
    }
  },
  "container-border": {
    DEFAULT: "var(--container-border)",
    foreground: "var(--container-border)",
    background: "var(--container-background-primary)"
  },
  "container-divider": {
    DEFAULT: "var(--container-divider)",
    foreground: "var(--container-divider)"
  },
  "temp-container": {
    DEFAULT: "var(--temp-container-highlight)",
    foreground: "var(--temp-container-highlight)"
  },
  danger: {
    50: "#FFF0EE",
    100: "#FFE1DE",
    200: "#FFD2CD",
    300: "#FFB2AB",
    400: "#FC7F79",
    500: "#F83446",
    600: "#DE1135",
    700: "#BB032A",
    800: "#950F22",
    900: "#520810"
  },
  warning: {
    50: "#FFF0E9",
    100: "#FEE2D4",
    200: "#FFD3BC",
    300: "#FFB48C",
    400: "#FC823A",
    500: "#E65300",
    600: "#C54600",
    700: "#A33B04",
    800: "#823006",
    900: "#461A00"
  },
  success: {
    50: "#EAF6ED",
    100: "#D3EFDA",
    200: "#B1EAC2",
    300: "#7FD99A",
    400: "#06C167",
    500: "#009A51",
    600: "#0E8345",
    700: "#166C3B",
    800: "#0D572D",
    900: "#002F14"
  },
  info: {
    50: "#EFF4FE",
    100: "#DEE9FE",
    200: "#CDDEFF",
    300: "#6DAAFB",
    400: "#6DAAFB",
    500: "#068BEE",
    600: "#266EF1",
    700: "#175BCC",
    800: "#1948A3",
    900: "#002661"
  },
  brand: {
    50: "#E8EBF8",
    100: "#B9C2EA",
    200: "#97A4E0",
    300: "#677AD1",
    400: "#4961C9",
    500: "#1C39BB",
    600: "#1934AA",
    700: "#142885",
    800: "#0F1F67",
    900: "#0C184F"
  },
  platinum: {
    bg: "#CDD6D8",
    fg: "#3B4447",
    stroke: "#69797E"
  }
};
