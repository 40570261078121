import { KeyValuePair, ResolvableTo } from "tailwindcss/types/config";

export const backdropBlur: ResolvableTo<KeyValuePair<string, string>> = {
  medium: "10px",
  0: "0px",
  10: "10px",
  20: "20px",
  30: "30px",
  40: "40px"
};
