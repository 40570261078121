import { KeyValuePair, ResolvableTo } from "tailwindcss/types/config";

export const fontSize: ResolvableTo<
  KeyValuePair<
    string,
    | string
    | [fontSize: string, lineHeight: string]
    | [
        fontSize: string,
        configuration: Partial<{
          lineHeight: string;
          letterSpacing: string;
          fontWeight: string | number;
        }>
      ]
  >
> = {
  caption4: [
    "6px",
    {
      lineHeight: "14px"
    }
  ],
  caption3: [
    "8px",
    {
      lineHeight: "14px"
    }
  ],
  caption5: [
    "9px",
    {
      fontWeight: 400,
      lineHeight: "9px"
    }
  ],
  caption2: [
    "10px",
    {
      lineHeight: "14px"
    }
  ],
  "caption2-strong": [
    "10px",
    {
      lineHeight: "14px",
      fontWeight: "600"
    }
  ],
  caption1: [
    "12px",
    {
      lineHeight: "16px",
      fontWeight: "400"
    }
  ],
  "caption1-strong": [
    "12px",
    {
      lineHeight: "16px",
      fontWeight: "600"
    }
  ],
  "caption1-stronger": [
    "12px",
    {
      lineHeight: "16px",
      fontWeight: "700"
    }
  ],
  body3: [
    "17px",
    {
      lineHeight: "22px",
      fontWeight: 600,
      letterSpacing: "-0.43px"
    }
  ],
  body2: [
    "16px",
    {
      lineHeight: "22px"
    }
  ],
  body1: [
    "14px",
    {
      lineHeight: "20px"
    }
  ],
  "body-two": ["15px", { fontWeight: 400, lineHeight: "20px", letterSpacing: "-0.23px" }],
  "body-2-strong": ["15px", { fontWeight: 600, lineHeight: "20px", letterSpacing: "-0.23px" }],
  "body-one": ["14px", { fontWeight: 400, lineHeight: "22px", letterSpacing: "-0.43px" }],
  "body-1-strong": ["14px", { fontWeight: 600, lineHeight: "22px", letterSpacing: "-0.43px" }],
  "subtitle-one": ["20px", { fontWeight: 600, lineHeight: "28px" }],
  "title-three": ["20px", { fontWeight: 700, lineHeight: "25px", letterSpacing: "-0.45px" }],
  "title-two": ["22px", { fontWeight: 700, lineHeight: "28px", letterSpacing: "-0.26px" }],
  "title-one": ["28px", { fontWeight: 800, lineHeight: "34px", letterSpacing: "0.38px" }],
  "large-title": ["34px", { fontWeight: 800, lineHeight: "41px", letterSpacing: "0.4px" }],
  display: ["60px", { fontWeight: 900, lineHeight: "72px", letterSpacing: "0.26px" }],
  "caption-two": ["10px", { fontWeight: 400, lineHeight: "14px" }],
  "caption-one": ["13px", { fontWeight: 400, lineHeight: "18px", letterSpacing: "-0.08px" }],
  "caption-1-strong": ["13px", { fontWeight: 600, lineHeight: "18px", letterSpacing: "-0.08px" }],

  // 100: "10px",
  // 200: "12px",
  // 300: "14px",
  // 400: "16px",
  // 500: "20px",
  // 600: "24px",
  // 700: "28px",
  // 800: "32px",
  // 900: "40px",
  // 1000: "68px"

  100: "0.625rem",
  200: "0.75rem",
  300: "0.875rem",
  400: "1rem",
  500: "1.25rem",
  600: "1.5rem",
  700: "1.75rem",
  800: "2rem",
  900: "2.5rem",
  1000: "4.25rem"
};
