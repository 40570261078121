import { KeyValuePair, ResolvableTo } from "tailwindcss/types/config";

export const spacing: ResolvableTo<KeyValuePair<string, string>> = {
  none: "0rem",
  xxs: "0.125rem",
  xs: "0.25rem",
  "s-nudge": "0.375rem",
  s: "0.5rem",
  "m-nudge": "0.625rem",
  m: "0.75rem",
  l: "1rem",
  xl: "1.25rem",
  "2xl": "1.5rem",
  "3xl": "2rem",
  "4xl": "3rem",
  "600": "4rem",
  "800": "8rem",
  "360": "2.25rem",
  "560": "3.5rem"
};
